import React, { useContext } from 'react';
import { Col, Row } from 'antd';
import {
  ClearAllContainer,
  ClearContainer,
  ClearSpan,
  IAIcon,
  IconClear,
  IconForm,
  IconSearch,
  SearchBarContainer,
  SearchButton,
  SearchContainer,
  SearchInput
} from './styles';
import lupaIcon from '../../../assets/images/lupa-search.png';
import { GlobalFiltersContext } from '../home/GlobalFiltersContext';
// import iaIcon from '../../../assets/images/ia-icon.png';

export const IASearchBar = ({
  top,
  buttonColor,
  type
}: {
  top?: number;
  buttonColor?: string;
  type?: string;
}) => {
  const {
    loadingGlobal,
    loadingAI,
    aiSearchText,
    setAiSearchText,
    updateDataAI,
    setAIChat,
    setLastAIResult
  } = useContext(GlobalFiltersContext);

  return (
    <>
      <SearchBarContainer style={top ? { marginTop: top } : {}}>
        {/* <IAIcon src={iaIcon} /> */}
        <SearchContainer>
          <IconSearch src={lupaIcon} alt="" />
          <SearchInput
            value={aiSearchText}
            placeholder='Ex: Liste artigos sobre improbidade que contenham "Sanção TCU".'
            onChange={(e) => setAiSearchText(e.target.value)}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                updateDataAI();
              }
            }}
          />
        </SearchContainer>
        <SearchButton
          style={
            buttonColor
              ? { background: buttonColor, cursor: 'pointer' }
              : { cursor: 'pointer' }
          }
          onClick={() => {
            updateDataAI();
          }}>
          OK
        </SearchButton>
        {type === 'clearAll' ? (
          <></>
        ) : (
          <ClearContainer
            onClick={() => {
              if (loadingGlobal || loadingAI) return;
              setLastAIResult({});
              setAIChat([]);
            }}>
            <IconClear type="delete" className="pointer" />{' '}
            <ClearSpan>Limpar chat</ClearSpan>
          </ClearContainer>
        )}
      </SearchBarContainer>
      {type === 'clearAll' ? (
        <Row>
          <Col>
            <ClearAllContainer
              onClick={() => {
                setLastAIResult({});
                setAIChat([]);
              }}>
              <span>
                Limpar pesquisa: <strong>NOVA BUSCA</strong>
              </span>
              <IconForm type="form" />
            </ClearAllContainer>
          </Col>
        </Row>
      ) : (
        <></>
      )}
    </>
  );
};

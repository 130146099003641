import { Button, Icon, Modal } from 'antd';
import React from 'react';
import { FolderSelectionModal } from '../../../../components/ContentMenu/FolderSelection/FolderSelectionModal';
import { ContentType } from '../../../../enums/ContentType';
import { ArticleType } from '../../../pesquisa/enums/ArticleType';
import { getInformativeCover } from '../../contentList/contentListGlobal/helpers/getInformativeCover';
import moment from 'moment';
import { useAuthCtx } from '../../../../features/auth_v2/hooks/useAuthCtx';
import { ConfirmAuthModal } from '../../../../features/auth_v2/components/ConfirmAuthModal';
import { useHistory } from 'react-router';

export const AddToFolder = ({ options }) => {
  const { is_authenticated_with_email } = useAuthCtx();
  const history = useHistory();

  const getResult = (it: any) => {
    if (!Object.keys(it).length) return;

    const base = `https://${process.env.REACT_APP_PUBLIC_BUCKET}.s3-sa-east-1.amazonaws.com`;
    const idSplited = String(it.id || '').split('-');
    const globalNumId = idSplited && idSplited[0] ? idSplited[0] : '';
    const editionNumId = idSplited && idSplited[1] ? idSplited[1] : '';
    let coverSrc = '';
    if (it.type === 'book') {
      coverSrc = `${base}/books/cover/${globalNumId}/editions/${editionNumId}.jpg`;
    }
    if (it.type === 'periodic') {
      coverSrc = `${base}/periodics/cover/${globalNumId}/${globalNumId}.jpg`;
    }
    if (it.type === 'video') {
      coverSrc = `${base}/videos/thumbs/${String(globalNumId).replaceAll(
        'V',
        ''
      )}-1.jpg`;
    }
    if (it.type === 'informative') {
      coverSrc = getInformativeCover(it.title);
    }

    const getFolderBody = () => {
      if (it.type === 'book') {
        return {
          url: '',
          subTitle: '',
          formattedDate: '',
          total: 0,
          parentId: globalNumId,
          editionId: editionNumId,
          relevantWords: null,
          contentType: ContentType.BOOK,
          articleType: ArticleType.TODOS,
          id: globalNumId,
          title: it.title,
          text: it.description,
          tags: [{ text: 'Livro', color: 'red' }],
          img: coverSrc,
          author: it.tags
            .filter((e) => String(e.id).startsWith('author'))
            .map((e) => e.title)
            .join(', '),
          areasInterest: it.tags
            .filter((e) => String(e.id).startsWith('area-interest'))
            .map((e) => e.title)
            .join(', ')
        };
      }
      if (it.type === 'periodic') {
        return {
          url: '',
          subTitle: '',
          formattedDate: '',
          total: 0,
          parentId: globalNumId,
          editionId: editionNumId,
          relevantWords: null,
          contentType: ContentType.PERIODIC,
          articleType: ArticleType.TODOS,
          id: globalNumId,
          title: it.parent_title_1,
          text: it.description,
          tags: [{ text: 'Revista', color: 'red' }],
          img: coverSrc,
          author: it.tags
            .filter((e) => String(e.id).startsWith('author'))
            .map((e) => e.title)
            .join(', '),
          areasInterest: it.tags
            .filter((e) => String(e.id).startsWith('area-interest'))
            .map((e) => e.title)
            .join(', ')
        };
      }
      if (it.type === 'video') {
        return {
          url: '',
          subTitle: '',
          formattedDate: '',
          total: 0,
          parentId: Number(String(globalNumId).replace('V', '')),
          editionId: 0,
          relevantWords: null,
          contentType: ContentType.VIDEO,
          articleType: ArticleType.TODOS,
          id: Number(String(globalNumId).replace('V', '')),
          title: it.title,
          text: it.description,
          tags: [{ text: 'Vídeo', color: 'red' }],
          img: coverSrc,
          author: it.tags
            .filter((e) => e.prefix === 'author')
            .map((e) => e.title)
            .join(', '),
          areasInterest: it.tags
            .filter((e) => e.prefix === 'area-interest')
            .map((e) => e.title)
            .join(', ')
        };
      }
      if (it.type === 'informative') {
        const subtype =
          String(it.subtype).charAt(0).toUpperCase() +
          String(it.subtype).slice(1);
        const date =
          it && it.publish_date
            ? moment(it.publish_date).format('DD/MM/YYYY')
            : '-';
        return {
          url: `/v2/informativo/${it.subtype}/${String(it.id).replace(
            'I',
            ''
          )}`,
          subTitle: `Informativo ${String(subtype)}`,
          formattedDate: '',
          total: 0,
          parentId: String(it.id).replace('I', ''),
          editionId: '',
          relevantWords: null,
          contentType: ContentType.INFORMATIVE,
          articleType: ArticleType.TODOS,
          id: Number(String(it.id).replace('-', '').replace('I', '')),
          title: `Informativo ${subtype}: ${date}`,
          text: '',
          tags: [{ text: 'Informativo', color: '' }],
          img: null,
          author: '',
          areasInterest: ''
        };
      }
      return {};
    };

    return getFolderBody();
  };

  const close = () => {
    options.setShowAddToFolder(false);
  };

  const footer = [
    <Button type={'primary'} key={'btn-1'} onClick={close}>
      <Icon type="close" />
      Fechar
    </Button>
  ];

  const result: any = getResult(options.folderItem);
  if (is_authenticated_with_email()) {
    return (
      <Modal
        maskClosable={true}
        title="Adicionar à pasta"
        visible={options.showAddToFolder}
        onCancel={close}
        onOk={close}
        footer={footer}
        maskStyle={{ background: 'rgba(0,0,0,.2)' }}>
        <FolderSelectionModal
          result={result}
          visible={options.showAddToFolder}
          onCancel={close}
        />
      </Modal>
    );
  } else {
    return (
      <ConfirmAuthModal
        {...{
          visible: options.showAddToFolder,
          onCancel: () => {
            close();
          },
          on_go_to_login_click: () => {
            history.push(`/login`);
          }
        }}
      />
    );
  }
};

import React from 'react';
import styled from 'styled-components';
import iconIA from '../../../assets/images/ia-initial-icon.png';

export const ChatSpanContainer = styled.span`
  float: left;
`;

export const ChatSpan = styled.span`
  width: 80px;
  background: #f3f4f6;
  border-radius: 5px;
  font-size: 10px;
  padding: 8px;
  padding-top: 6px;
  font-weight: bold;
`;

export const ChatMessage = styled.div`
  padding-left: 12px;
  font-size: 12px;
  margin-top: -2px;
  margin-left: 5px;
`;

const Container = styled.div`
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 635px;
  margin: auto;
`;

const Title = styled.h1`
  font-size: 22px;
  font-weight: normal;
  span {
    color: #0066ff;
    font-weight: bold;
  }
`;

const Subtitle = styled.p`
  font-size: 12px;
  color: gray;
  margin-bottom: 20px;
`;

const ContainerElements = styled.div`
  border-top: 2px solid #f4f4f4;
  border-bottom: 2px solid #f4f4f4;
  display: flex;
  align-items: stretch;
  margin-top: 58px;
`;

const ContainerChatBlock = styled.div`
  padding: 20px 55px 20px 12px;
`;

const ContainerChatIcon = styled.div``;

const ContainerChat = styled.div`
  text-align: left;
  display: flex;
  align-items: stretch;
`;

const TimeCheck = styled.div`
  font-size: 12px;
  color: green;
  margin-left: 12px;
  margin-right: 2px;
`;

const Time = styled.div`
  font-size: 12px;
`;

const Icon = styled.img`
  margin-top: -5px;
  width: 95px;
  margin-left: -35px;
`;

export const IAInitialPage = () => {
  return (
    <Container>
      <Title>
        Uma nova <span>Experiência</span> de Pesquisa
      </Title>
      <Subtitle>Um CHAT específico para FILTROS DE CONTEÚDO.</Subtitle>
      <ContainerElements>
        <ContainerChatBlock>
          <ContainerChat>
            <ChatSpanContainer>
              <ChatSpan>VOCÊ</ChatSpan>
            </ChatSpanContainer>
            <ChatMessage>
              <span>
                Liste artigos sobre IMPROBIDADE ADMINISTRATIVA que não contenha
                o termo "Sanções TCU"
              </span>
            </ChatMessage>
            <TimeCheck>✔✔</TimeCheck>
            <Time> 13:45</Time>
          </ContainerChat>

          <ContainerChat style={{ marginTop: 22 }}>
            <ChatSpanContainer>
              <ChatSpan>VOCÊ</ChatSpan>
            </ChatSpanContainer>
            <ChatMessage>
              <span>
                Quais livros da Editora FÓRUM abordam o tema Compliance na
                gestão pública?
              </span>
            </ChatMessage>
            <TimeCheck>✔✔</TimeCheck>
            <Time> 13:45</Time>
          </ContainerChat>
        </ContainerChatBlock>
        <ContainerChatIcon>
          <Icon src={iconIA} alt="IA Pesquisas Fórum" />
        </ContainerChatIcon>
      </ContainerElements>
    </Container>
  );
};
